<template>
  <section>
    <v-card class="pa-1">
      <v-card-title>
        Padi Student Lookup
      </v-card-title>
      <v-card-text>
        <div style="height: 300px;">
          <div v-if="isLoading">
            <div class="text-center pa-8 rounded-lg" style="height: 100%;">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
          <div v-else-if="certificates.length >= 1" style="height: 100%; overflow-x: scroll;">
            <div class="mb-4">
              <v-row no-gutters>
                <v-col class="title font-weight-bold text-decoration-underline">
                  {{ firstName }} {{ lastName }}
                </v-col>
                <v-col sm="3">
                  <v-btn @click="doInit" class="ml-auto" color="primary" block>Reset</v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-for="(cert, index) in certificates" :key="index">
              <div class="title">{{ cert.certification }}</div>
              {{ cert.date }}
            </div>
          </div>
          <div v-else>
            <v-form @submit.prevent="doSearch" ref="form">
              <v-text-field label="First Name" v-model="firstName" :rules="inputs.rules.simplyRequired" outlined dense></v-text-field>
              <v-text-field label="Middle Initial" v-model="middleInitial" outlined dense></v-text-field>
              <v-text-field label="Last Name" v-model="lastName" :rules="inputs.rules.simplyRequired" outlined dense></v-text-field>
              <v-menu ref="datePickerMenu" v-model="datePickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dob" label="Date of Birth" readonly v-bind="attrs" v-on="on" outlined dense :rules="inputs.rules.simplyRequired"></v-text-field>
                </template>
                <v-date-picker v-model="dob" :active-picker.sync="activeDatePicker" :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1920-01-01" @change="$refs.datePickerMenu.save(date)"></v-date-picker>
              </v-menu>
              <v-btn type="submit" color="primary" block>Search</v-btn>
            </v-form>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: 'padiCertifcationLookup',
  data: () => ({
    isLoading: false,
    firstName: null,
    lastName: null,
    middleInitial: String(),
    dob: null,
    activeDatePicker: null,
    datePickerMenu: false,
    certificates: []
  }),
  methods: {
    doSearch () {
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated) {
        this.isLoading = true

        this.$services.padi.doGetCertifications(this.firstName, this.middleInitial, this.lastName, this.dob).then(certificates => {
          this.certificates = certificates
        }).catch(_ => {
          this.$root.$emit('showError', 'Student not found, try again.')
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doInit () {
      this.firstName = null
      this.lastName = null
      this.middleInitial = String()
      this.dob = null
      this.certificates = []
    }
  },
  watch: {
    datePickerMenu (val) {
      val && setTimeout(() => (this.activeDatePicker = 'YEAR'))
    }
  }
}
</script>

<style scoped>

</style>
